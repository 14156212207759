import { useAuthentication } from '@netfront/gelada-identity-library';

import { Link, SingleFormPage } from '../components';

const PAGE_TITLE = 'Page not found';

const Error404Page = () => {
  const { isAuthenticated } = useAuthentication();

  return (
    <SingleFormPage meta={{ seoDescription: 'page not found', seoTitle: '404' }} title="Login">
      <div className="p-10 radius bg-white-opacity-4">
        <h1 className="h1 color-red-700 mb-2">404</h1>
        <h2 className="mb-10">{PAGE_TITLE}</h2>

        <p className="mb-10 font-size-p">Sorry, the page you were looking for could not be found.</p>

        <Link href={isAuthenticated ? '/partner-hub' : '/'} theme="default">
          {`Return ${isAuthenticated ? 'to dashboard' : 'to homepage'}`}
        </Link>
      </div>
    </SingleFormPage>
  );
};

// eslint-disable-next-line import/no-default-export
export default Error404Page;
